<template>
  <div>
    <Nav/>
    <div class="post-box">
      <div class="post-input-box in">
        <h5>게시글 제목</h5>
        <input type="text" v-model="referenceInfo.title" class="custom-input-box"/>
        <h5>상세내용</h5>
        <textarea v-model="referenceInfo.desc" class="custom-input-box textarea-lock"/>
      </div>
      <div class="create-btn">
        <button @click="addPostingData">
          <img src="@/assets/images/IA/icon_edit.png" alt="">
          등록
        </button>
      </div>
    </div>
    <Progress :isProgress="isProgress"/>
  </div>
</template>

<script>

import Nav from "@/components/Nav";
import {firebase} from "@/firebase/firebaseConfig";
import {firebaseError} from "@/firebase/firebaseError";
import Progress from "@/components/Progress";

export default {
  name: 'ReferenceCreate',
  components: {Nav, Progress,},
  data() {
    return {
      fbCollection: 'references',
      isProgress: false,
      referenceInfo: {
        orderNo: 0,
        title: '',
        desc: '',
        regDate: '',
        viewCount: 0,
        writerInfo: {
          writerName: this.$store.state.user.displayName,
          writerUid: this.$store.state.user.uid,
          writerEmail: this.$store.state.user.email,
        }
      },
    }
  },
  mounted() {
    this.init();

  },
  methods: {
    async init() {
      await this.getNextNo();
    },
    getNextNo() {
      const self = this;
      const db = firebase.firestore();
      db.collection('orderNumberController')
          .doc(self.fbCollection)
          .get()
          .then(async (snapshot) => {
            self.referenceInfo.orderNo = snapshot.data().orderNo;
          })
          .catch((err) => {
            // 실패 후 실행할 코드
            self.isProgress = false;
            firebaseError(err);
          })
    },

    validate() {
      const self = this;
      if (self.referenceInfo.title.trim().length === 0) {
        alert('제목을 입력해 주세요')
        return false;
      }
      if (self.referenceInfo.desc.trim().length === 0) {
        alert('상세내용을 입력해 주세요');
        return false;
      }
      return true;
    },
    addPostingData() {
      const self = this;
      if (self.validate()) {
        self.isProgress = true;
        const db = firebase.firestore();
        const _referenceInfo = {...self.referenceInfo};
        _referenceInfo.regDate = firebase.firestore.Timestamp.fromDate(new Date());

        const batch = db.batch();

        const referenceRef = db.collection(self.fbCollection).doc();
        batch.set(referenceRef, _referenceInfo);

        const orderRef = db.collection("orderNumberController").doc(self.fbCollection);
        batch.update(orderRef, {orderNo: firebase.firestore.FieldValue.increment(+1)});

        batch.commit().then(async () => {
          self.isProgress = false;
          await self.$router.push('/reference');
        });

      }
    }
  }
}
</script>

<style scoped>

.post-box {
  height: 427px;
  max-width: 1110px;
  margin: 40px auto 98px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 565px;
}

.post-input-box {
  height: 367px;
  padding: 30px 20px;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  margin-bottom: 20px;
}

.post-input-box h5 {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #71717A;
}

input {
  margin-bottom: 30px;
  outline: 0;
}

.post-input-box textarea {
  outline: 0;
  height: 173px;
}

.post-box .create-btn {
  margin-left: auto;
}

.post-box .create-btn button {
  width: 70px;
  height: 40px;
  background: #2E8AF4;
  border-radius: 4px;
  border: 0;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-box .create-btn button img {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.textarea-lock {
  resize: none;
}

</style>